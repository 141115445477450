// in posts.js
import React from 'react';
import { List, Datagrid, Edit, Create, SimpleForm, DateField, TextField, EditButton, DeleteButton, DisabledInput, BooleanInput, TextInput, Filter, DateInput, } from 'react-admin';
import Bookmark from '@material-ui/icons/Bookmark';

import { CardActions, ShowButton,BooleanField, ReferenceField,ReferenceManyField, ReferenceArrayField, ChipField, SingleFieldList } from 'react-admin';
import Button from '@material-ui/core/Button';
import { SaveButton, Toolbar, RadioButtonGroupInput, required, SelectInput } from 'react-admin';


export const AdIcon = Bookmark;


const AdFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const AdvertisementList = (props) => (
    <List {...props} filters={<AdFilter/>}>
        <Datagrid rowClick="edit">
            <TextField source="type" />
            <TextField source="platform" />
            <ReferenceField source="author" reference="member" target="id">
                <TextField source="displayname" />
            </ReferenceField>
            <DateField source="createdAt" />
            <BooleanField source="isEnable" />
            <ReferenceManyField label="Category" source="category" reference="forumcategory" target="ident">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceManyField>
        </Datagrid>
    </List>
);

const AdvertisementTitle = ({ record }) => {
    return <span>Category {record ? `"${record.name}"` : ''}</span>;
};

const AdvertisementEditActions = ({ basePath, data, resource, props }) => (
    <CardActions >
        {/* Add your custom actions */}

    </CardActions>
);

const AdvertisementEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);
export const AdvertisementEdit = (props) => (
    <Edit title={<AdvertisementTitle />} actions={<AdvertisementEditActions />} {...props}>
        <SimpleForm toolbar={<AdvertisementEditToolbar />}>
                <TextInput source="name" validate={required()}/>
                <TextInput source="ident" validate={required()}/>
                <TextInput source="sort" />
                <TextInput source="color" />
                <BooleanInput source="showBW" />
                
       </SimpleForm>
    </Edit>
);


export const AdvertisementCreate = (props) => (
    <Create title="Create an Advertisement" {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()}/>
            <TextInput source="ident" validate={required()}/>
            <TextInput source="sort" />
            <TextInput source="color" />
            <BooleanInput source="showBW" />
        </SimpleForm>
    </Create>
);