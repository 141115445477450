// in posts.js
import React from 'react';
import { List, Datagrid, Edit, Create, SimpleForm, DateField, BooleanField, EditButton, DeleteButton, DisabledInput, BooleanInput, TextInput, Filter, DateInput, } from 'react-admin';
import LibraryBooks from '@material-ui/icons/LibraryBooks';

import { CardActions, ShowButton, ReferenceManyField, SingleFieldList, TextField, ReferenceArrayField } from 'react-admin';
import Button from '@material-ui/core/Button';
import { SaveButton, Toolbar, RadioButtonGroupInput, required, CreateButton, RefreshButton, SelectInput, ReferenceArrayInput, SelectArrayInput, TabbedForm,FormTab,ChipField } from 'react-admin';


export const ForumPostIcon = LibraryBooks;

const ForumPostListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter
}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <CreateButton basePath={basePath} />
        <RefreshButton />
        {/* Add your custom actions */}
    </CardActions>
);

const ForumPostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput label="Post ID" source="post_id" alwaysOn />

    </Filter>
);

export const ForumPostList = (props) => (
    <List {...props} filters={<ForumPostFilter/>} actions={<ForumPostListActions/>}>
        <Datagrid rowClick="edit">
            <TextField source="post_id" />
            <TextField source="title" />
            <ReferenceManyField label="Category" source="category" reference="forumcategory" target="ident">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceManyField>
            <TextField source="author.name" />
            <DateField source="createdAt" showTime/>
            <BooleanField source="isHide" />
            <BooleanField source="isLock" />
            <BooleanField source="isPoll" />
            <EditButton basePath="/forumcategory" />
        </Datagrid>
    </List>
);

const ForumPostTitle = ({ record }) => {
    return <span>Post {record ? `"${record.title} by ${record.author.name}"` : ''}</span>;
};

const ForumPostEditActions = ({ basePath, data, resource, props }) => (
    <CardActions >
        {/* Add your custom actions */}

    </CardActions>
);

const ForumPostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);
export const ForumPostEdit = (props) => (
    <Edit title={<ForumPostTitle />} actions={<ForumPostEditActions />} {...props}>
        <TabbedForm toolbar={<ForumPostEditToolbar />}>
            <FormTab label="content">
                <DisabledInput source="title" validate={required()}/>
                <ReferenceArrayInput label="Category" source="category" reference="forumcategory" >
                    <SelectArrayInput optionText="name" optionValue="ident"/>
                </ReferenceArrayInput>
                <DisabledInput source="content" validate={required()}/>
                <DisabledInput source="author.name" validate={required()}/>
                <DisabledInput source="createdAt" validate={required()}/>
                <DisabledInput source="lastReply.at" validate={required()}/>
            </FormTab>
            <FormTab label="Action">
                <BooleanInput source="isLock"/>
                <BooleanInput source="isHide"/>
                <BooleanInput source="isPoll"/>
            </FormTab>
            <FormTab label="Replies">
                <ReferenceManyField
                        label="Reply"
                        reference="forumpostreply"
                        target="post"
                        addLabel={false}
                        >
                        <Datagrid>
                            <TextField source="content" />
                            <TextField source="author.name" />
                            <BooleanField source="isHide"/>
                            <DateField source="createdAt" showTime />
                            <DeleteButton />
                        </Datagrid>
                </ReferenceManyField>
            </FormTab>
       </TabbedForm>
    </Edit>
);


export const ForumPostCreate = (props) => (
    <Create title="Create a Post" {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()}/>
            <TextInput source="ident" validate={required()}/>
            <TextInput source="sort" />
            <TextInput source="color" />
            <BooleanInput source="showBW" />
        </SimpleForm>
    </Create>
);