// in posts.js
import React from 'react';
import { List, Datagrid, Edit, Create, SimpleForm, DateField, TextField, EditButton, DeleteButton, DisabledInput, BooleanInput, TextInput, Filter, DateInput, } from 'react-admin';
import Bookmark from '@material-ui/icons/Bookmark';

import { CardActions, ShowButton, ReferenceManyField } from 'react-admin';
import Button from '@material-ui/core/Button';
import { SaveButton, Toolbar, RadioButtonGroupInput, required, SelectInput } from 'react-admin';


export const ForumCategoryIcon = Bookmark;


const ForumCategoryFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const ForumCategoryList = (props) => (
    <List {...props} filters={<ForumCategoryFilter/>}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="ident" />
            <TextField source="sort" />
            <TextField source="color" />
            <EditButton basePath="/forumcategory" />
        </Datagrid>
    </List>
);

const ForumCategoryTitle = ({ record }) => {
    return <span>Category {record ? `"${record.name}"` : ''}</span>;
};

const ForumCategoryEditActions = ({ basePath, data, resource, props }) => (
    <CardActions >
        {/* Add your custom actions */}

    </CardActions>
);

const ForumCategoryEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);
export const ForumCategoryEdit = (props) => (
    <Edit title={<ForumCategoryTitle />} actions={<ForumCategoryEditActions />} {...props}>
        <SimpleForm toolbar={<ForumCategoryEditToolbar />}>
                <TextInput source="name" validate={required()}/>
                <TextInput source="ident" validate={required()}/>
                <TextInput source="sort" />
                <TextInput source="color" />
                <BooleanInput source="showBW" />
                
       </SimpleForm>
    </Edit>
);


export const ForumCategoryCreate = (props) => (
    <Create title="Create a Category" {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()}/>
            <TextInput source="ident" validate={required()}/>
            <TextInput source="sort" />
            <TextInput source="color" />
            <BooleanInput source="showBW" />
        </SimpleForm>
    </Create>
);