import jsonServerProvider from './jsonProvider';
import { fetchUtils } from 'react-admin';

if (process.env.NODE_ENV == 'production') {
    var apiurl = '/admin';

} else {
    var apiurl = 'http://127.0.0.1:8012/admin';

}
//export default jsonServerProvider(apiurl);


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}

const dataProvider = jsonServerProvider(apiurl, httpClient);


export { dataProvider as default , apiurl};