import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { Admin, Resource,ListGuesser } from 'react-admin';
import dataProvider from './dataProvider';
import authProvider from './authProvider';

import { MemberList, MemberEdit, MemberCreate, MemberIcon } from './components/member';
import { ForumCategoryList, ForumCategoryEdit, ForumCategoryCreate, ForumCategoryIcon } from './components/forumcategory';
import { ForumPostList, ForumPostEdit, ForumPostCreate, ForumPostIcon } from './components/forumpost';
import { AdvertisementList, AdvertisementEdit, AdvertisementCreate, AdIcon } from './components/advertisement';

import LoginPage from './components/auth/LoginPage';
import LogoutBtn from './components/auth/LogoutBtn';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Admin dataProvider={dataProvider} authProvider={authProvider}>
          <Resource name="member"  list={MemberList} edit={MemberEdit} create={MemberCreate} icon={MemberIcon} />
          <Resource name="usertokens" />
          <Resource name="forumcategory"  list={ForumCategoryList} edit={ForumCategoryEdit} create={ForumCategoryCreate} icon={ForumCategoryIcon} />
          <Resource name="forumpost"  list={ForumPostList} edit={ForumPostEdit} create={ForumPostCreate} icon={ForumPostIcon} />
          <Resource name="forumpostreply" />
          <Resource name="advertisement" list={AdvertisementList} edit={AdvertisementEdit} create={AdvertisementCreate}/>
        </Admin>
      </div>
    );
  }
}

export default App;
