// in posts.js
import React from 'react';
import { List, Datagrid, Edit, Create, SimpleForm, DateField, TextField, EditButton, DeleteButton, DisabledInput, TextInput, Filter, DateInput, } from 'react-admin';
import AccountBox from '@material-ui/icons/AccountBox';

import { CardActions, ShowButton, ReferenceManyField } from 'react-admin';
import Button from '@material-ui/core/Button';
import { SaveButton, Toolbar, RadioButtonGroupInput,TabbedForm, FormTab, required, SelectInput, BooleanField,BooleanInput } from 'react-admin';
import PwdResetBtn from './member/PwdResetBtn';
import { CreateButton, RefreshButton } from 'react-admin';

export const MemberIcon = AccountBox;


const MemberFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const MemberListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter
}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <CreateButton basePath={basePath} />
        <RefreshButton />
        {/* Add your custom actions */}
    </CardActions>
);


export const MemberList = (props) => (
    <List {...props} filters={<MemberFilter/>} actions={<MemberListActions/>}>
        <Datagrid rowClick="edit">
            <TextField source="email" />
            <TextField source="username" />
            <TextField source="displayname" />
            <BooleanField source="isEnable" />
            <BooleanField source="isBanned" />
            <BooleanField source="verified" />
            <EditButton basePath="/users" />
        </Datagrid>
    </List>
);

const MemberTitle = ({ record }) => {
    return <span>User {record ? `"${record.email}"` : ''}</span>;
};

const MemberEditActions = ({ basePath, data, resource, props }) => (
    <CardActions >
        {/* Add your custom actions */}
        <PwdResetBtn record={data} />
    </CardActions>
);

const MemberEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);
export const MemberEdit = (props) => (
    <Edit title={<MemberTitle />} actions={<MemberEditActions />} {...props}>
        <TabbedForm toolbar={<MemberEditToolbar />}>
            <FormTab label="summary">
            
                <DisabledInput source="email" validate={required()}/>
                <TextInput source="displayname" validate={required()}/>
                <TextInput source="username" validate={required()}/>
                <SelectInput source="role" choices={[
                    { id: 'root', name: 'Root' },
                    { id: 'admin', name: 'Admin' },
                    { id: 'user', name: 'User' },
                ]} />
            </FormTab>
            <FormTab label="profile">
                <TextInput label="Date of Brith" source="dob" />
                <RadioButtonGroupInput label="Gender" source="profile.gender" choices={[
                    { id: 'male', name: 'Male' },
                    { id: 'female', name: 'Female' }
                ]} /> 
            </FormTab>
            <FormTab label="Logon Info">
                <BooleanInput source="isEnable" />
                <BooleanInput source="isBanned" />
                <BooleanInput source="verified" />
                <BooleanInput source="isDeveloper" />
                <DisabledInput label="Created at" source="activity.created_at" />
                <DisabledInput label="Last Logon at" source="activity.last_logon" />

            </FormTab>
            <FormTab label="Tokens">
                <ReferenceManyField
                    label="Tokens"
                    reference="usertokens"
                    target="user"
                    >
                    <Datagrid>
                        <TextField source="os" />
                        <TextField source="device" />
                        <TextField source="agent" />
                        <TextField source="token" />
                        <DateField source="lastuse_at" />
                        <DateField source="created_at" />
                        <DeleteButton />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
       </TabbedForm>
    </Edit>
);


export const MemberCreate = (props) => (
    <Create title="Create a Member" {...props}>
        <SimpleForm>
            <TextInput source="email" />
            <TextInput source="displayname" />
            <TextInput source="username" />
            <TextInput label="Date of Brith" source="dob" />
            <RadioButtonGroupInput source="profile.gender" choices={[
                { id: 'male', name: 'Male' },
                { id: 'female', name: 'Female' }
            ]} /> 
        </SimpleForm>
    </Create>
);