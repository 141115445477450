// in src/componenets/LoginPage.js
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';

import Button from '@material-ui/core/Button';


import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';


import {
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    SaveButton,
    SimpleForm,
    TextInput,
    LongTextInput,
    UPDATE,
    REDUX_FORM_NAME
} from 'react-admin';

class LoginPage extends Component {
    submit = (e) => {
        e.preventDefault();
        // gather your data/credentials here
        const credentials = { };

        // Dispatch the userLogin action (injected by connect)
        this.props.userLogin(credentials);
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    ADMIN Page
                </header>
                <form onSubmit={this.submit}>
                    <div>
                        <div>
                            <TextInput
                                name="username"
                            />
                        </div>
                        <div>
                            <TextInput
                                name="password"
                            />
                        </div>
                    </div>
                    <CardActions >
                        <Button
                            variant="raised"
                            type="submit"
                            color="primary"
                            fullWidth
                        >
                        </Button>
                    </CardActions>
                </form>
            </div>
            
        );
    }
};

export default connect(undefined, { userLogin })(LoginPage);