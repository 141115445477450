// in PasswordRestBtn.js
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change, submit, isSubmitting } from 'redux-form';
import {
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    Button,
    SaveButton,
    SimpleForm,
    TextInput,
    LongTextInput,
    UPDATE,
    REDUX_FORM_NAME
} from 'react-admin';
import PropTypes from 'prop-types';


import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import dataProvider , { apiurl} from '../../dataProvider';

class PwdResetBtn extends Component {
    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit, reset } = this.props;
        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit('pwd-reset');
    };

    handleSubmit = values => {
        const { record, fetchStart, fetchEnd, showNotification } = this.props;

        // Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();
        // As we want to know when the new post has been created in order to close the modal, we use the
        // dataProvider directly
        const pwdUpdate = { password: values.password};
        fetch(apiurl+`/member/pwd/${record.id}`, {   method: 'PUT', 
                                                    headers: {'Accept': 'application/json',
                                                                'Content-Type':'application/json'},
                                                    body: JSON.stringify(pwdUpdate) })
            .then(() => {
                showNotification('user.password_updated');
                //push('/comments');
                this.setState({showDialog : false});
            })
            .catch((e) => {
                showNotification(e.message, 'error');

            })
            .finally(() => {
                // Dispatch an action letting react-admin know a API call has ended
                fetchEnd();
            });
    };

    render() {
        const { showDialog } = this.state;
        const { isSubmitting } = this.props;

        return (
            <Fragment>
                <Button onClick={this.handleClick} label="Reset Pwd">
                </Button>
                <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Reset Password"
                >
                    <DialogTitle>Reset Password</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            // We override the redux-form name to avoid collision with the react-admin main form
                            form="pwd-reset"
                            resource="users"
                            // We override the redux-form onSubmit prop to handle the submission ourselves
                            onSubmit={this.handleSubmit}
                            // We want no toolbar at all as we have our modal actions
                            toolbar={null}
                            validate={validatePwdReset}
                        >
                            <TextInput source="password" type="password" validate={required()} />
                            <TextInput label="Confirm Password" source="confirmpassword" type="password" validate={required()} />

                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        />
                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('pwd-reset')(state)
});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

PwdResetBtn.propTypes = {
    fetchStart: PropTypes.func,
    fetchEnd: PropTypes.func,
    record: PropTypes.object,
};

const validatePwdReset = values => {
    const errors = {};
    if (!values.confirmpassword) {
        errors.confirmpassword = 'required';
    } else if (values.password !== values.confirmpassword){
        errors.confirmpassword = 'Password mismatched';

    }
    return errors;
};

export default connect(mapStateToProps, mapDispatchToProps)(
    PwdResetBtn
);